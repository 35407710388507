import {getChallenge, getChallenges} from '../api/challenge'
import { useMemo } from 'react'

export const useChallenges = () => {

  return useMemo(() => {

		const handleFetchChallenges = async ({ setLoading = () => {} }) => {

			setLoading(true)

			try {
				const { data } = await getChallenges()

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data)
          return {}

				return data

			} catch (error) {
        /* gestionar el error */
				console.log('could not fetch reptes ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		const handleFetchChallenge = async ({ id, setLoading = () => {} }) => {

			setLoading(true)

			try {
				const { data } = await getChallenge(id)

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data)
          return {}
				return { data }

			} catch (error) { /* TODO -> Handle error */
        /* gestionar el error */
				console.log('could not fetch repte with id: ', id, error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchChallenges,
			handleFetchChallenge,
		}

	}, [])
}

import React , { useEffect, useState } from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import { useChallenges } from '../hooks/useChallenges'
import { useSolutionsByChallenge } from '../hooks/useSolutions'
import { useNavigate, useLocation } from 'react-router-dom'
import { SERVER_URL } from '../api'
import { alertError, alertSuccess } from '../utils/logger'
import { postSolution } from '../api/solution'
import { getEconomicSectors } from '../api/economicSector'
import icoFile from "../assets/iconFile.png"
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'

function getEmptySolution() {
  return {
    name: '',
    surname: '',
    email: '',
    companyName: '',
    title: '',
    description: '',
    link: '',
    usedTechnologies: '',
    valueAdded: '',
    previousSuccessStories: '',
    challenge: '',
    image: '',
    logo: '',
    report: '',
    conditions: false
  }
}

function Repte({intl}) {
  const [dataSol, setDataSol] = useState([])
  const [solution, setSolution] = useState(getEmptySolution())
  const location = useLocation()
  const navigate = useNavigate()

  const id = location.state?.id || null

  const [loading, setLoading] = useState(false)
  const [loadingSol, setLoadingSol] = useState(false)

  const [data, setData] = useState([])

  const { handleFetchChallenge, handleFetchChallenges } = useChallenges()
  const { handleFetchSolutionsByChallenge } = useSolutionsByChallenge()

  const [backgroundStyle, setBackgroundStyle] = useState({})

	const lang = useLang()

  useEffect(() => {
    window.scrollTo(0, 0)
    const asyncFetchSolutionsAndSectors = async (challenge, challengeId) => {
      const {dataSolutions, errorSol} = await handleFetchSolutionsByChallenge({id: challengeId, setLoadingSol})
      if (errorSol){
        alert(`error recuperant solucions del repte ${errorSol.message}`)
        navigate('/reptes')
      }
      setDataSol(dataSolutions)

      getEconomicSectors().then((resp) => {
        if (resp.status === 200) {
          console.log('economicSecotr', resp.data)
          const sector = challenge.economicSector ? resp.data.find(cat => cat._id === challenge.economicSector) : null
          setData({...challenge, economicSector: sector?.fullName[lang] || sector?.fullName.es || '---' })
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not get economic sectors.' })
      })
      
     const _backgroundStyle = challenge.image
        ? {backgroundImage: `url("${SERVER_URL}/${challenge.image.replaceAll('\\', '/')}")`}
        : {}
      setBackgroundStyle(_backgroundStyle)
    }

    const asyncFetchChallenge = async () => {
      if (!id) {
        let data = await handleFetchChallenges({setLoading})
        const challenge = data.find(elem => elem.challengeName && (Object.keys(elem.challengeName).findIndex(language => '/repte/' + elem.challengeName[language]?.replace(/\s+/g, '-').replace(/[^\w\s-]/g, '').replace(/-+/g, '-').toLowerCase() === location.pathname) >= 0))
        if (challenge) asyncFetchSolutionsAndSectors(challenge, challenge._id)
        else {
          alert(`error recuperant repte`)
          navigate('/reptes')
        }
      } else {
        const {data, error} = await handleFetchChallenge({id: id, setLoading}) 
        if (error){
          alert(`error recuperant repte ${error.message}`)
          navigate('/reptes')
        } else asyncFetchSolutionsAndSectors(data, id)
      }
    }

    asyncFetchChallenge()
  }, [])

  function saveSolution() {
    if (solution.conditions === false){
      alertError({ error: null, customMessage: intl.formatMessage({ id: 'CHALLENGE.ACCEPTPOLICY' }) })
      return
    }
    solution.challenge = id
    postSolution(solution).then(res => {
      if (res.status === 201) {
        alertSuccess({ title: 'Saved!', customMessage: intl.formatMessage({ id: 'CHALLENGE.SOLUTIONREGISTEREDOK' }) })
        navigate('/reptes')
      }
    }).catch(error => {
      alertError({ error: error, customMessage: intl.formatMessage({ id: 'CHALLENGE.SOLUTIONREGISTEREDNOTOK' }) })
    })
  }
  const handleChange = (element) => (event) => {
    setSolution({ ...solution, [element]: event.target.value })
  }

  return (
        <div>
      		<CustomHelmet
      			title={data.challengeName && data.challengeName[lang]}
      			description={data.definition && data.definition[lang]}
      		/>
          {/* <section class="section section--image section--header" style={backgroundStyle}> */}
          <section class="section section--image section--header" style={backgroundStyle}>
            <div class="container">
              <div class="row">
                <div class="col-3 mb-5">
                  <img src={`${SERVER_URL}/${data.logo}`} style={{height: '50px'}}/>
                </div>
              </div>
              <div class="row">
                <div class="col-11">
                  <h1>{data.challengeName && data.challengeName[lang]}</h1>
                </div>
              </div>
            </div>
          </section>
          <section class="container my-4">
            <h3 class="text-white" style={{'background-color': 'rgb(239, 13, 6)', 'padding-left': '10px'}}><FormattedMessage id='CHALLENGE.DESCRIPTION' /></h3>
            <p className="py-3">{data.definition && data.definition[lang]}</p>
            <br/>
            <h3 class="text-white" style={{'background-color': 'rgb(239, 13, 6)', 'padding-left': '10px'}}><FormattedMessage id='CHALLENGE.CONTEXT' /></h3>
            <p className="py-3">{data.context && data.context[lang]}</p>
            <br/>
            {data.report && data.report != '' && <h3 class="text-white" style={{'background-color': 'rgb(239, 13, 6)', 'padding-left': '10px'}}><FormattedMessage id='CHALLENGE.REPORTDOWNLOAD' /></h3>}
            {data.report && data.report != '' && <p className="py-3">
              <a href={SERVER_URL+'/'+data.report} target="_blank">
                <img src={icoFile} alt="File" style={{'width':'50px', 'height':'50px'}} />
              </a>
            </p>}
            <br/>
            <h3 class="text-white" style={{'background-color': 'rgb(239, 13, 6)', 'padding-left': '10px'}}><FormattedMessage id='CHALLENGE.SECTOR' /></h3>
            <p className="py-3">{data.economicSector}</p>
            <br/>

            {data.link && <><h3 class="text-white" style={{'background-color': 'rgb(239, 13, 6)', 'padding-left': '10px'}}><FormattedMessage id='CHALLENGE.LINK' /></h3>
            <p className="py-3">{data.link}</p>
            <br/></>}

            <div style={{'text-align': 'center'}}>
              <q style={{'font-size': '28px', 'font-style': 'italic', 'text-align': 'center !important'}}>{data.encourageParticipation && data.encourageParticipation[lang]}</q>
            </div>
            <br/><br/>
            {
              (dataSol?.data?.length !== 0) && <><h4 class="text-white py-3 text-center" style={{'background-color': 'rgb(72, 72, 72)', 'padding-left': '10px'}}><FormattedMessage id='CHALLENGE.THIS_CHALLENGE_HAS' values={{numSolutions: dataSol?.data?.length}} /></h4><br/><br/></>
            }
          </section>
          <section class="container-fluid sectionred2 py-4">
            <section class="container">
              <div class="col-12 text-white">
                <h1 class="text-white"><FormattedMessage id='CHALLENGE.HAVE_THE_SOLUTION' /></h1>
                <br/>
                <p class="text-white"><FormattedMessage id='CHALLENGE.ENTER_THE_INFORMATION' /></p>
                <div class="row">
                  <div class="form-group col-md-6 mb-4">
                    <label for="fullName"><FormattedMessage id='CHALLENGE.NAME' /> *</label>
                    <input
                    id={`name`}
                    onChange={handleChange('name')}
                    value={solution.name}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "CHALLENGE.NAME"})}
                    required
                    class="form-control rounded-0"/>
                  </div>
                  <div class="form-group col-md-6 mb-4">
                    <label for="Cognoms"><FormattedMessage id='CHALLENGE.SURNAME' /> *</label>
                    <input
                    id={`surname`}
                    onChange={handleChange('surname')}
                    value={solution.surname}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "CHALLENGE.SURNAME"})}
                    required
                    class="form-control rounded-0"/>
                  </div>
                </div>
                <div class="form-group mb-4">
                  <label for="Email"><FormattedMessage id='CHALLENGE.EMAIL' /> *</label>
                  <input
                  id={`email`}
                  onChange={handleChange('email')}
                  value={solution.email}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder={intl.formatMessage({id: "CHALLENGE.EMAIL"})}
                  required
                  class="form-control rounded-0"/>
                </div>
                <div class="form-group mb-4">
                  <label for="NomEmpresa"><FormattedMessage id='CHALLENGE.BUSINESS' /> *</label>
                  <input
                  id={`companyName`}
                  onChange={handleChange('companyName')}
                  value={solution.companyName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder={intl.formatMessage({id: "CHALLENGE.BUSINESS"})}
                  required
                  class="form-control rounded-0"/>
                </div>
                <div class="form-group mb-4">
                  <label for="NomSolucio"><FormattedMessage id='CHALLENGE.SOLUTION_TITLE' /> *</label>
                  <input
                  id={`title`}
                  onChange={handleChange('title')}
                  value={solution.title}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder={intl.formatMessage({id: "CHALLENGE.SOLUTION_TITLE"})}
                  required
                  class="form-control rounded-0"/>
                </div>
                <div class="form-group mb-4">
                  <label for="DefinicioSolucio"><FormattedMessage id='CHALLENGE.SOLUTION_DESCRIPTION' /> *</label>
                  <textarea
                  id={`description`}
                  onChange={handleChange('description')}
                  rows="3"
                  required
                  className="form-control rounded-0"></textarea>
                </div>
                <div class="form-group mb-4">
                  <label for="Tecnologia"><FormattedMessage id='CHALLENGE.USED_TECHNOLOGIES' /> *</label>
                  <input
                  id={`usedTechnologies`}
                  onChange={handleChange('usedTechnologies')}
                  value={solution.usedTechnologies}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder={intl.formatMessage({id: "CHALLENGE.USED_TECHNOLOGIES"})}
                  required
                  class="form-control rounded-0"/>
                </div>
                <div class="form-group mb-4">
                  <label for="ValorAfegit"><FormattedMessage id='CHALLENGE.VALUE_ADDED' /> *</label>
                  <input
                  id={`valueAdded`}
                  onChange={handleChange('valueAdded')}
                  value={solution.valueAdded}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder={intl.formatMessage({id: "CHALLENGE.VALUE_ADDED"})}
                  required
                  class="form-control rounded-0"/>
                  <small id="ValorAfegitHelpBlock" class="form-text">
                    <FormattedMessage id='CHALLENGE.WHAT_ADVANTAGES' />
                  </small>
                </div>
                <div class="form-group mb-4">
                  <label for="CasosPrevis"><FormattedMessage id='CHALLENGE.PREVOUS_SUCCESS_STORIES' /> *</label>
                  <input
                  id={`previousSuccessStories`}
                  onChange={handleChange('previousSuccessStories')}
                  value={solution.previousSuccessStories}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder={intl.formatMessage({id: "CHALLENGE.PREVOUS_SUCCESS_STORIES"})}
                  required
                  class="form-control rounded-0"/>
                  <small id="CasosPrevisHelpBlock" class="form-text">
                    <FormattedMessage id='CHALLENGE.TELL_WHERE' />
                  </small>
                </div>
                <div class="form-group mb-4">
                   <input type="checkbox"
                     checked={solution.conditions}
                     onChange={() => setSolution({ ...solution, conditions: !solution.conditions })}
                     style={{width:'20px', height:'20px'}}
                     required />&nbsp;
                   <label for="Condicions"><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "CHALLENGE.HAVE_READ"}) } } /></label>
                </div>
                <div class="form-group mb-4">
                  <small>
                    <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "CHALLENGE.IN_COMPLIANCE"}) } } />
                  </small>
                </div>
                <div class="form-group mb-4">
                  <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "CHALLENGE.THE_FIELDS_WITH"}) } } />
                </div>
                <button
                  onClick={() => saveSolution()}
                  class="btn btn-primary rounded-0 my-4"><FormattedMessage id='CHALLENGE.PUBLISH_THE_SOLUTION' /></button>
              </div>
            </section>
          </section>
        </div>
    	)
  }

  export default injectIntl(Repte)

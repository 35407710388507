import axios from 'axios'
import {API} from '../index'
const instance = axios.create()

export const getEconomicSectors = async () => {
  const _endpoint = 'economicsector'
  const URL = `${API}/${_endpoint}`

  return instance.get(URL)
}

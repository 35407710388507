import React , { useEffect, useState } from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import { useChallenges } from '../hooks/useChallenges'
import { SERVER_URL } from '../api'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'

const GridElem = ({status, companyName, challengeName, logo, image, url, id}) => {
  if (status === 'APP')
    return(
      <div class='col-md-6 mb-4'>
        <Link
          to={url}
          state={{id}}
          title={challengeName}
          class="project"
        >
          <div class="project__image"><img src={image} alt={challengeName}/></div>
          <div class="project__content">
            <div class="project__avatar avatar" style={{display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}>
              <img src={logo} alt={companyName}/>
            </div>
            <p class="project__author">{companyName}</p>
            <h3 class="project__title">{challengeName}</h3>
          </div>
        </Link>
      </div>
    )
}
function Reptes({intl}) {
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])

  const { handleFetchChallenges } = useChallenges()

	const lang = useLang()

  useEffect(() => {
    window.scrollTo(0, 0)
    const asyncFetch = async () => {
      const data = await handleFetchChallenges({setLoading})
      setData(data)
    }
    asyncFetch()
  }, [])

  return (
        <div style={{'margin-top':'165px'}}>
      		<CustomHelmet
      			title={intl.formatMessage({ id: 'CHALLENGES.TITLESEO' }) || ''}
      			description={intl.formatMessage({ id: 'CHALLENGES.DESCRIPTIONSEO' }) || ''}
      		/>
          <div className="container mt-4">
            <ol itemprop="breadcrumb" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb">
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item breadcrumb-item--icon">
                <a itemprop="url" href="/">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16">
                    <path d="M16,1,1,20.56H7.09v9h5.75s-1.37-8.94,3.16-9,3.16,9,3.16,9h5.75v-9H31Z"></path>
                  </svg>
                  <span itemprop="name">Inici</span>
                </a>
              </li>
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item">
                <span itemprop="name"><FormattedMessage id='CHALLENGES.CHALLENGES' /></span>
              </li>
            </ol>
          </div>
          <div className="container">
            <h1><FormattedMessage id='CHALLENGES.ONGOINGCHALLENGES' /></h1>
            <div className="row">
              {data?.map(elem =>
                <GridElem
                  status={elem.status}
                  companyName={elem.companyName}
                  challengeName={elem.challengeName && elem.challengeName[lang]}
                  logo={`${SERVER_URL}/${elem.logo}`}
                  image={`${SERVER_URL}/${elem.image}`}
                  url={`/repte/${elem.challengeName && elem.challengeName[lang]?.replace(/\s+/g, '-').replace(/[^\w\s-]/g, '').replace(/-+/g, '-').toLowerCase()}`}
                  id={elem._id}
                />
            )}
            </div>
          </div>
        </div>
    	)
  }

  export default injectIntl(Reptes)

import {getEconomicSectors} from '../api/economicSector'
import { useMemo } from 'react'

export const useEconomicSectors = () => {

  return useMemo(() => {

		const handleFetchEconomicSectors = async ({ setLoading = () => {} }) => {

			setLoading(true)

			try {
				const { data } = await getEconomicSectors()

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data)
          return {}

				return data

			} catch (error) {
        /* gestionar el error */
				console.log('could not fetch reptes ', error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchEconomicSectors
		}

	}, [])
}

import {getSolutionByChallengeId, getSolutions} from '../api/solution'
import { useMemo } from 'react'

export const useSolutionsByChallenge = () => {

  return useMemo(() => {

		const handleFetchSolutionsByChallenge = async ({ id, setLoading = () => {} }) => {

			setLoading(true)

			try {
				const data = await getSolutionByChallengeId(id)

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data)
          return {}

				return {dataSolutions : data}

			} catch (error) { /* TODO -> Handle error */
        /* gestionar el error */
				console.log('could not fetch solution with challenge id: ', id, error)
				return { error }
			} finally {
				setLoading(false)
			}
		}

		return {
			handleFetchSolutionsByChallenge
		}

	}, [])
}

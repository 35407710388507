import axios from 'axios'
import { uploadSingleFile } from "../files"
import {API} from '../index'
const instance = axios.create()

export const getChallenges = async () => {
  const _endpoint = 'challenge'
  const URL = `${API}/${_endpoint}`

  return instance.get(URL)
}

export const getChallenge = async (id) => {
  const _endpoint = `challenge/${id}`
  const URL = `${API}/${_endpoint}`

  return instance.get(URL)
}

// Save challenge
export const postChallenge = async (challenge, image, logo) => {
    if (image) {
      const response = await uploadSingleFile(image, 'challenges')
      challenge.image = response.data.fileURL
    }
    if (logo) {
      const response = await uploadSingleFile(logo, 'challenges')
      challenge.logo = response.data.fileURL
    }
    return instance.post(`${API}/challenge`, challenge)
}

export const putChallengeUser = async (id, idUser) => {
  let challenge = {}
  challenge.user = idUser
  return instance.put(`${API}/challenge/user/${id}`, challenge)
}

import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import Publicar from './pages/Publicar'
import Registre from './pages/Registre'
import Repte from './pages/Repte'
import Reptes from './pages/Reptes'
import Home from './pages/Home'
import SobreHubtalent from './pages/SobreHubtalent'

function Rutas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Home /> } />
          <Route path='sobre-hubtalent' element={<SobreHubtalent />} />
          <Route path='publicar-repte' element={<Publicar />} />
          <Route path='registre' element={<Registre />} />
          <Route path='reptes' element={<Reptes />} />
          <Route path='repte/:name' element={<Repte />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Rutas

import React , { useEffect, useState } from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import { postUser } from '../api/user'
import { putChallengeUser } from '../api/challenge'
import { alertError, alertSuccess } from '../utils/logger'
import { useNavigate, useLocation } from 'react-router-dom'
import CustomHelmet from '../components/seo/CustomHelmet'

function getEmptyUser() {
  return {
    fullName: '',
    email: '',
    role: 'client',
    password: '',
    repeatPassword: '',
    gdpr: false
  }
}

function Registre({intl}) {
  const [user, setUser] = useState(getEmptyUser())
  const navigate = useNavigate()
  const { state } = useLocation();

  const { id, username, email } = state;

  user.fullName = username
  user.email = email

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function saveUser() {
    if (user.password !== user.repeatPassword) {
      alertError({ error: null, customMessage: intl.formatMessage({ id: 'REGISTER.PASSWORDSNOTMATCH' }) })
      return
    }
    if (user.gdpr === false){
      alertError({ error: null, customMessage: intl.formatMessage({ id: 'CHALLENGE.ACCEPTPOLICY' }) })
      return
    }
    postUser(user).then(res => {
      if (res.status === 201) {
        //TODO: set user id to challenge previously created -> id
        putChallengeUser(id, res.data.id).then(resCh => {
          if (resCh.status === 200) {
            //mail a usuario que se ha registrado correctamente
            alertSuccess({ title: 'Saved!', customMessage: intl.formatMessage({ id: 'REGISTER.USERSAVEDOK' }) })
            navigate('/')
          }
        }).catch(error => {
          alertError({ error: error, customMessage: intl.formatMessage({ id: 'REGISTER.USERSAVEDNOTOK' }) })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: intl.formatMessage({ id: 'REGISTER.USERSAVEDNOTOK' }) })
    })
  }
  const handleChange = (element) => (event) => {
    setUser({ ...user, [element]: event.target.value })
  }
  return (
        <div style={{'margin-top':'165px'}}>
      		<CustomHelmet
      			title={intl.formatMessage({ id: 'REGISTER.TITLESEO' }) || ''}
      			description={intl.formatMessage({ id: 'REGISTER.DESCRIPTIONSEO' }) || ''}
      		/>
          <div className="container mt-4">
            <ol itemprop="breadcrumb" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb">
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item breadcrumb-item--icon">
                <a itemprop="url" href="/">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16">
                    <path d="M16,1,1,20.56H7.09v9h5.75s-1.37-8.94,3.16-9,3.16,9,3.16,9h5.75v-9H31Z"></path>
                  </svg>
                  <span itemprop="name"><FormattedMessage id='PUBLISH.HOME' /></span>
                </a>
              </li>
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item">
                <span itemprop="name"><FormattedMessage id='REGISTER.REGISTER_USER' /></span>
              </li>
            </ol>
          </div>
          <section class="container-fluid py-4">
            <section class="container">
              <div class="row">
                <div class="col-12 text-black">
                  <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "REGISTER.REGISTER_EXPLANATION"}) } } />
                </div>
              </div>
            </section>
          </section>
          <section class="container-fluid sectionred2 py-4">
            <section class="container">
              <div class="row">
                <div class="col-12 col-md-6 text-white">
                  <h2 class="text-white"><FormattedMessage id='REGISTER.REGISTER_YOUR_USER' /></h2>
                  <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "REGISTER.WHY_REGISTER"}) } } />
                </div>
                <div class="col-12 col-md-6 text-white">
                  <div class="form-group mb-4 ">
                    <label for="Nom"><FormattedMessage id='REGISTER.NAME' /> *</label>
                    <input
                    id={`fullName`}
                    onChange={handleChange('fullName')}
                    value={user.fullName}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "REGISTER.NAME"})}
                    disabled
                    required
                    className="form-control rounded-0"/>
                  </div>
                  <div class="form-group mb-4">
                    <label for="Email"><FormattedMessage id='REGISTER.EMAIL' /> *</label>
                    <input
                    id={`email`}
                    onChange={handleChange('email')}
                    value={user.email}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "REGISTER.EMAIL"})}
                    disabled
                    required
                    className="form-control rounded-0"/>
                  </div>
                  <div class="form-group mb-4">
                    <label for="NomEmpresa"><FormattedMessage id='REGISTER.PASSWORD' /> *</label>
                    <input
                    type='password'
                    id={`password`}
                    onChange={handleChange('password')}
                    value={user.password}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "REGISTER.PASSWORD"})}
                    required
                    className="form-control rounded-0"/>
                  </div>
                  <div class="form-group mb-4">
                    <label for="repeatPassword"><FormattedMessage id='REGISTER.REPPASSWORD' /> *</label>
                    <input
                    type='password'
                    id={`repeatPassword`}
                    onChange={handleChange('repeatPassword')}
                    value={user.repeatPassword}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "REGISTER.REPPASSWORD"})}
                    required
                    className="form-control rounded-0"/>
                  </div>
                  <div class="form-group mb-4">
                     <input type="checkbox"
                       checked={user.gdpr}
                       onChange={() => setUser({ ...user, gdpr: !user.gdpr })}
                       style={{width:'20px', height:'20px'}}
                       required />&nbsp;
                     <label for="Condicions"><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "REGISTER.COMPLIANCE"}) } } /></label>
                  </div>
                  <button
                    onClick={() => saveUser()}
                    class="btn btn-primary rounded-0 my-4"><FormattedMessage id='REGISTER.REGISTER_USER' /></button>
                </div>
              </div>
            </section>
          </section>
        </div>
    	)
  }

  export default injectIntl(Registre)

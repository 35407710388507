import axios from 'axios'
import {API} from '../index'

const instance = axios.create()

// Get solution by challenge id
export const getSolutionByChallengeId = (id) => {
  return instance.get(`${API}/solution/challenge/${id}`)
}

// Save solution
export const postSolution = async (solution) => {
    return instance.post(`${API}/solution`, solution)
}

import React , { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useChallenges } from '../hooks/useChallenges'
import {FormattedMessage, injectIntl} from 'react-intl'
import icochallenge from "../assets/hubicon-diana.png"
import icosolutions from "../assets/ico_ambit_cap_negre@2x.png"
import pizarra from "../assets/pizarra2-hub.jpg"
import iconet from "../assets/ico_ambit_gent_negre@2x.png"
import { SERVER_URL } from '../api'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'
import { PopoverHeader } from 'react-bootstrap'

const GridElem = ({companyName, challengeName, logo, image, url, id}) => {
    return(
      <div class="col-12 col-md-4 py-3">
        <Link
          to={url}
          state={{id}}
          title="Reduir o neutralitzar l'empremta de carboni a la companyia"
          class="project"
        >
          <div class="project__image"><img src={image} alt={challengeName} /></div>
          <div class="project__content">
            <div class="project__avatar avatar" style={{display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}>
              <img src={logo} alt={companyName} />
            </div>
            <p class="project__author">{companyName}</p>
            <h3 class="project__title">{challengeName}</h3>
          </div>
        </Link>
      </div>
    )
}

function Home({intl}) {
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])

  const { handleFetchChallenges } = useChallenges()

	const lang = useLang()

  useEffect(() => {
    window.scrollTo(0, 0)
    const asyncFetch = async () => {
      const data = await handleFetchChallenges({setLoading})
      setData(data)
    }
    asyncFetch()
  }, [])

  // intl.formatMessage({id: "HOME.HEADER"})
  return (
      <div>
    		<CustomHelmet
    			title={intl.formatMessage({ id: 'HOME.TITLESEO' }) || ''}
    			description={intl.formatMessage({ id: 'HOME.DESCRIPTIONSEO' }) || ''}
    		/>
        <section class="container-fluid header-home d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-9">
              <h1>
                <FormattedMessage id='HOME.HEADER' />
              </h1>
              <a href="/publicar-repte" class="button-home btn my-2"><FormattedMessage id='HOME.PUBLISH_CHALLENGE' /></a>
            </div>
          </div>
        </div>
      </section>
      <section class="sectionred">
        <div class="container py-5">
          <div class="row">
            <div class="col-7 d-none d-md-block">
              <img src={pizarra} alt="Empreses" class="imghome1" />
            </div>
            <div class="col-12 col-md-5">
              <h1><FormattedMessage id='HOME.WHAT_IS' /></h1>
              <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "HOME.OUR_MISSION"}) } } />
            </div>
          </div>
        </div>
      </section>
      <section class="sectionred">
        <div class="container py-5">
          <div class="row">
            <div class="col-sm">
              <img src={icochallenge} alt="Empreses" class="icon" style={{width: '70px'}} />
              <span style={{color: 'white', 'margin-left': '15px', 'font-size': '25px'}}><FormattedMessage id='HOME.TITLE_CHALLENGES' /></span><br/><br/>
              <div class="line" style={{flex: '1 1 0%', 'border-bottom': '3px solid black', 'margin-bottom': '5px'}}></div>
              <p><FormattedMessage id='HOME.TEXT_CHALLENGES' /></p>
              <br/><br/>
            </div>
            <div class="col-sm">
              <img src={icosolutions} alt="Empreses" class="icon" style={{width: '70px'}} />
              <span style={{color: 'white', 'margin-left': '15px', 'font-size': '25px'}}><FormattedMessage id='HOME.TITLE_SOLUTIONS' /></span><br/><br/>
              <div class="line" style={{flex: '1 1 0%', 'border-bottom': '3px solid black', 'margin-bottom': '5px'}}></div>
              <p><FormattedMessage id='HOME.TEXT_SOLUTIONS' /></p>
              <br/><br/>
            </div>
            <div class="col-sm">
              <img src={iconet} alt="Empreses" class="hubicon" style={{width: "70px"}} />
              <span style={{color: 'white', 'margin-left': '15px', 'font-size': '25px'}}><FormattedMessage id='HOME.TITLE_NET' /></span><br/><br/>
              <div class="line" style={{flex: '1 1 0%', 'border-bottom': '3px solid black', 'margin-bottom': '5px'}}></div>
              <p><FormattedMessage id='HOME.TEXT_NET' /><br/></p>
            </div>
          </div>
        </div>
      </section>
      <section class="sectionred quin-es-el-teu-repte">
        <div class="container py-5">
          <div class="row p-4 p-md-0">
            <div class="col-12 col-md-6 offset-0 offset-md-6 col-txtwhite-v2 p-4 p-md-5">
              <h1><FormattedMessage id='HOME.WHAT_IS_YOUR_CHALLENGE' /></h1>
              <p><div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "HOME.THIS_IS_THE_SPACE"}) } } /></p>
              <div className="text-center text-md-start mt-4">
                <a href="/publicar-repte" class="button-home btn"><FormattedMessage id='HOME.PUBLISH_CHALLENGE' /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sectiongrey">
        <div class="container py-5">
          <div class="row">
            <div class="col-12">
              <h1 class="homewhitetitle"><FormattedMessage id='HOME.FEAT_CHALLENGES' /></h1>
            </div>
          </div>
          <div class="row">
            {data?.filter(item => item.status === 'APP' && (item.featured === true || item.featured === 'true'))
              .slice(0, 6).map(elem =>
                <GridElem
                  companyName={elem.companyName}
                  challengeName={elem.challengeName && elem.challengeName[lang]}
                  logo={`${SERVER_URL}/${elem.logo}`}
                  image={`${SERVER_URL}/${elem.image}`}
                  url={`/repte/${elem.challengeName && elem.challengeName[lang]?.replace(/\s+/g, '-').replace(/[^\w\s-]/g, '').replace(/-+/g, '-').toLowerCase()}`}
                  id={elem._id}
                />
            )}
          </div>
        </div>
      </section>
      <section class="sectionred agenda-proper py-0 py-md-5">
        <div class="container py-5">
          <div class="row p-4 p-md-0">
            <div class="col-12">
              <p style={{color: 'white'}}><FormattedMessage id='HOME.NEXT_SCHEDULE' /></p>
              <h1>Taller de co-creació: defineix el teu repte digital!</h1>
              <a href="https://agenciaeconomica.amb.cat/ca/agenda/detall/-/activitat/reptes-hubtalent/12495826/7154508" class="button-homew btn"><FormattedMessage id='HOME.I_WANT_TO_PARTICIPATE' /></a>
            </div>
          </div>
        </div>
      </section>
    </div>
  	)
  }

  export default injectIntl(Home)

import React, { useState, useEffect } from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import CustomHelmet from '../components/seo/CustomHelmet'
import about from "../assets/hub-talent@2x.png"

function SobreHubtalent({intl}) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
        <div style={{'margin-top':'165px'}}>
      		<CustomHelmet
      			title={intl.formatMessage({ id: 'ABOUT_HUBTALENT.TITLESEO' }) || ''}
      			description={intl.formatMessage({ id: 'ABOUT_HUBTALENT.DESCRIPTIONSEO' }) || ''}
      		/>
          <div class="container page mt-4">
            <ol itemprop="breadcrumb" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb">
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item breadcrumb-item--icon">
                <a itemprop="url" href="/">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16">
                    <path d="M16,1,1,20.56H7.09v9h5.75s-1.37-8.94,3.16-9,3.16,9,3.16,9h5.75v-9H31Z"></path>
                  </svg>
                  <span itemprop="name"><FormattedMessage id='ABOUT_HUBTALENT.HOME' /></span>
                </a>
              </li>
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item">
                <span itemprop="name"><FormattedMessage id='ABOUT_HUBTALENT.ABOUT_HUBTALENT' /></span>
              </li>
            </ol>
            <h1 class="my-4"><FormattedMessage id='ABOUT_HUBTALENT.ABOUT_HUBTALENT' /></h1>
            <div class="row align-items-center">
              <div class="col">
                <h2><strong><FormattedMessage id='ABOUT_HUBTALENT.WHAT_IS_HUBTALENT' /></strong></h2>
                <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "ABOUT_HUBTALENT.HUTALENT_IS_A_SPACE"}) } } />
              </div>
              <div class="col-md-auto">
                <img src={about} alt="HUB Talent"/>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-12 col-md-6">
                <h2><strong><FormattedMessage id='ABOUT_HUBTALENT.WHAT_IS_IT_FOR' /></strong></h2>
                <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "ABOUT_HUBTALENT.HUBTALENT_ACT_AS"}) } } />
              </div>
              <div class="col-12 col-md-6">
                <h2><strong><FormattedMessage id='ABOUT_HUBTALENT.HOW_IT_WORKS' /></strong></h2>
                <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "ABOUT_HUBTALENT.HUBTALENT_IS_A_SPACE"}) } } />
              </div>
            </div>
          </div>
        </div>
    	)
  }

  export default injectIntl(SobreHubtalent)

import React , { useEffect, useState } from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import { useEconomicSectors } from '../hooks/useEconomicSectors'
import {getConfig} from '../translations/i18n'
import { postChallenge } from '../api/challenge'
import { alertError, alertSuccess } from '../utils/logger'
import { useNavigate } from 'react-router-dom'
import CustomHelmet from '../components/seo/CustomHelmet'
import { useLang } from '../translations/i18n'

function getEmptyChallenge() {
  return {
    challengeName: {},
		companyName: '',
		context: {},
		definition: {},
		link: '',
		comments: {},
		encourageParticipation: {},
		logo: '',
		image: '',
		economicSector: '',
    active: false,
		user: '',
		status: 'PEND'
  }
}

function Publicar({intl}) {
  const [challenge, setChallenge] = useState(getEmptyChallenge())
	const [selectedImage, setSelectedImage] = useState(null)
  const [selectedLogo, setSelectedLogo] = useState(null)
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])

  const { handleFetchEconomicSectors } = useEconomicSectors()
  const navigate = useNavigate()

	const lang = useLang()

  useEffect(() => {
    window.scrollTo(0, 0)
    const asyncFetch = async () => {
      const economicSectors = await handleFetchEconomicSectors({setLoading})
      setData(economicSectors)
    }
    asyncFetch()
  }, [])

  function saveChallenge() {
    if (!selectedImage) {
      alertError({ error: null, customMessage: intl.formatMessage({ id: 'PUBLISH.UPLOADIMAGE' }) })
      return
    }
    postChallenge(challenge, selectedImage, selectedLogo).then(res => {
      if (res.status === 201) {
        //mail aviso a admin de nuevo reto
        alertSuccess({ title: 'Saved!', customMessage: intl.formatMessage({ id: 'PUBLISH.CHALLENGECREATED' }) })
        navigate('/registre', {state: {id: res.data.id, username: challenge.username, email: challenge.email}})
      }
    }).catch(error => {
      alertError({ error: error, customMessage: intl.formatMessage({ id: 'PUBLISH.COULDNOTSAVE' }) })
    })
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!challenge[element]) challenge[element] = {}
      let newText = challenge[element]
      newText[lang] = event.target.value
      setChallenge({ ...challenge, [element]: newText })
    } else setChallenge({ ...challenge, [element]: event.target.value })
  }

  return (
        <div style={{'margin-top':'165px'}}>
      		<CustomHelmet
      			title={intl.formatMessage({ id: 'PUBLISH.TITLESEO' }) || ''}
      			description={intl.formatMessage({ id: 'PUBLISH.DESCRIPTIONSEO' }) || ''}
      		/>
          <div className="container mt-4">
            <ol itemprop="breadcrumb" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb">
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item breadcrumb-item--icon">
                <a itemprop="url" href="/">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16">
                    <path d="M16,1,1,20.56H7.09v9h5.75s-1.37-8.94,3.16-9,3.16,9,3.16,9h5.75v-9H31Z"></path>
                  </svg>
                  <span itemprop="name"><FormattedMessage id='PUBLISH.HOME' /></span>
                </a>
              </li>
              <li itemprop="child" itemscope="itemscope" itemtype="http://schema.org/Breadcrumb" class="breadcrumb-item">
                <span itemprop="name"><FormattedMessage id='PUBLISH.PUBLISH_YOUR_CHALLENGE' /></span>
              </li>
            </ol>
          </div>
          <section class="container-fluid py-4">
            <section class="container">
              <div class="row">
                <div class="col-12 text-black">
                  <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "PUBLISH.THE_CONTEXT"}) } } />
                </div>
              </div>
            </section>
          </section>
          <section class="container-fluid sectionred2 py-4">
            <section class="container">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2 class="text-white"><FormattedMessage id='PUBLISH.PUBLISH_YOUR_CHALLENGE' /></h2>
                  <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "PUBLISH.IS_THE_MOMENT"}) } } />
                </div>
                <div class="col-12 col-md-6 text-white">
                  <div class="form-group mb-4">
                    <label for="Nom"><FormattedMessage id='PUBLISH.NAME' /> *</label>
                    <input
                    id={`username`}
                    onChange={handleChange('username')}
                    value={challenge.username}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "PUBLISH.NAME"})}
                    required
                    className="form-control rounded-0"/>
                  </div>
                  <div class="form-group mb-4">
                    <label for="Email"><FormattedMessage id='PUBLISH.EMAIL' /> *</label>
                    <input
                    id={`email`}
                    onChange={handleChange('email')}
                    value={challenge.email}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "PUBLISH.EMAIL"})}
                    required
                    className="form-control rounded-0"/>
                  </div>
                  <div class="form-group mb-4">
                    <label for="NomEmpresa"><FormattedMessage id='PUBLISH.BUSINESS' /> *</label>
                    <input
                    id={`companyName`}
                    onChange={handleChange('companyName')}
                    value={challenge.companyName}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "PUBLISH.BUSINESS"})}
                    required
                    className="form-control rounded-0"/>
                  </div>
                  <div class="form-group mb-4">
                    <label for="NomRepte"><FormattedMessage id='PUBLISH.CHALLENGE_TITLE' /> *</label>
                    <input
                    id={`challengeName`}
                    value={challenge.challengeName && challenge.challengeName[lang] || ''}
                    onChange={handleChange('challengeName', lang)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "PUBLISH.CHALLENGE_TITLE"})}
                    required
                    className="form-control rounded-0"/>
                    <small id="NomRepteHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.CHALLENGE_IS_IMPORTANT' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="DefinicioRepte"><FormattedMessage id='PUBLISH.CONTEXT_DEFINITION' /> *</label>
                    <textarea
                    id={`context`}
          					value={challenge.context && challenge.context[lang] || ''}
          					onChange={handleChange('context', lang)}
                    rows="3"
                    required
                    className="form-control rounded-0"></textarea>
                    <small id="ContextRepteHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.EXPLAIN_BRIEFLY' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="DefinicioRepte"><FormattedMessage id='PUBLISH.CHALLENGE_DEFINITION' /> *</label>
                    <textarea
                    id={`definition`}
          					value={challenge.definition && challenge.definition[lang] || ''}
          					onChange={handleChange('definition', lang)}
                    rows="3"
                    required
                    className="form-control rounded-0"></textarea>
                    <small id="DefinicioRepteHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.PUT_IT_BRIEFLY' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="Sector"><FormattedMessage id='PUBLISH.CHALLENGE_SECTOR' /> *</label>
                    <select
                    onChange={handleChange('economicSector')}
                    id="sector" name="sector" class="form-control rounded-0">
                      <option disabled selected value><FormattedMessage id='PUBLISH.SELECTANOPTION' /></option>
                      {data?.map((option) => {
                        return<option id={option._id} value={option._id}>{(getConfig().selectedLang === 'ca') ? Object.values(option.fullName.ca) : (getConfig().selectedLang === 'es') ? Object.values(option.fullName.es) : Object.values(option.fullName.en)}</option>
                      })}
                    </select>
                  </div>
                  <div class="form-group mb-4">
                    <label for="Logo"><FormattedMessage id='PUBLISH.BUSINESS_LOGO' /> *</label>
                    <input
                    onChange={(e) => {
                      setSelectedLogo(e.target.files[0])
                    }}
                    type="file" id="logo" name="logo" placeholder="" required accept="image/*" class="form-control rounded-0 text-white" style={{background: 'none', border: 'none'}}/>
                    <small id="ResultatsHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.IN_PNG_OR_JPEG_FORMAT' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="ImatgeRepte"><FormattedMessage id='PUBLISH.CHALLENGE_IMAGE' /> *</label>
                    <input
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0])
                    }}
                    type="file" id="image" name="image" placeholder="" required accept="image/*" class="form-control rounded-0 text-white" style={{background: 'none', border: 'none'}}/>
                    <small id="ImatgeRepteHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.CHALLENGE_CHOOSE_IMAGE' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="LinkRepte"><FormattedMessage id='PUBLISH.CHALLENGE_LINK' /></label>
                    <input
                    id={`link`}
                    onChange={handleChange('link')}
                    value={challenge.link}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "PUBLISH.CHALLENGE_LINK"})}
                    required
                    className="form-control rounded-0"/>
                    <small id="LinkRepteHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.YOU_HAVE_THE_CHALLENGE' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="Comentaris"><FormattedMessage id='PUBLISH.COMMENTS' /></label>
                    <textarea
                    id={`comments`}
          					value={challenge.comments && challenge.comments[lang] || ''}
          					onChange={handleChange('comments', lang)}
                    rows="3"
                    required
                    className="form-control rounded-0"></textarea>
                    <small id="ComentarisHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.KEEP_IN_MIND' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="Motivacio"><FormattedMessage id='PUBLISH.ENCOURAGE' /></label>
                    <input
                    id={`link`}
          					value={challenge.encourageParticipation && challenge.encourageParticipation[lang] || ''}
          					onChange={handleChange('encourageParticipation', lang)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={intl.formatMessage({id: "PUBLISH.ENCOURAGE"})}
                    required
                    className="form-control rounded-0"/>
                    <small id="MotivacioHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.WRITE_PHRASE' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <label for="InformeRepte"><FormattedMessage id='PUBLISH.CHALLENGE_REPORT' /></label>
                    <input type="file" accept="application/msword, text/plain, application/pdf" id="report" name="report" placeholder="Informe del repte" class="form-control rounded-0 text-white" style={{background: 'none', border: 'none'}}/>
                    <small id="InformeRepteHelpBlock" class="form-text"><FormattedMessage id='PUBLISH.IF_YOU_HAVE_A_DOCUMENT' /></small>
                  </div>
                  <div class="form-group mb-4">
                    <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "PUBLISH.IN_COMPLIANCE"}) } } />
                  </div>
                  <div class="form-group mb-4">
                    <div dangerouslySetInnerHTML={ { __html: intl.formatMessage({id: "PUBLISH.THE_FIELDS_WITH"}) } } />
                  </div>
                  <button
                    onClick={() => saveChallenge()}
                    class="btn btn-primary rounded-0 my-4"><FormattedMessage id='PUBLISH.PUBLISH_THE_CHALLENGE' /></button>
                </div>
              </div>
            </section>
          </section>
        </div>
    	)
  }

  export default injectIntl(Publicar)
